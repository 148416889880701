.message-entry {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.name {
  color: var(--main-colour);
}

.user-message-entry {
  justify-content: flex-end;
}

.other-message-entry {
  justify-content: flex-start;
}

.chat-bubble {
  background: var(--secondary-colour-2-60);
  position: relative;
  border-radius: 15px;
  max-width: 80%;
  width: fit-content;
  padding: 10px 14px;
  box-shadow: 0px 6px 6px rgba(90, 90, 90, 0.4);
}

.chat-bubble-left {
  align-self: flex-start;
}

.chat-bubble-right {
  align-self: flex-end;
}

.text {
  color: var(--chat-text-color);
  max-width: 95%;
  white-space: normal;
  font-size: 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.self-message {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.edit-and-time {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.text-time {
  color: var(--chat-text-color);
  align-self: flex-end;
  font-size: 0.8rem;
}

.role-bubble {
  position: absolute;
  top: -7px;
  right: -7px;
  cursor: pointer;
}

/* modal content */
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.modal-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.core-skill-and-role {
  font-size: 1rem;
}
