.pathway-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.path-container {
  padding: 8px 25px;
  width: 95%;
  border: 3px solid var(--main-colour);
  border-radius: 30px;
  margin: 6px;
  background-color: var(--secondary-colour-2-60);
  cursor: pointer;
}

.path-container strong {
  color: var(--main-colour);
}

.path-selected {
  background-color: var(--main-colour);
}

.path-selected strong {
  color: var(--secondary-colour-2-60);
}
