.rating-average-container {
  width: 90%;
  color: var(--text-colour);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
  margin-bottom: 5%;
}

.rating-average-container h1 {
  font-weight: bold;
  font-size: calc(0.8rem + 0.5vh);
}

.rating-average-container > * + * {
  margin-top: 2rem;
}

.rating-average-container .title {
  width: 100%;
}

.navigation {
  margin: calc(3rem + 1vh) 0;
  width: 100%;
}

.title > hr {
  border-top: 1px solid var(--main-colour);
  color: var(--main-colour);
  width: 95%;
  margin: auto;
  margin-top: 0.5rem;
}

.rating-average-container > .rating-row {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  /* Animation */
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.rating-row .textual-content {
  text-align: start;
  padding: 0 2.5rem;
}

.textual-content h2 {
  font-size: calc(0.8rem + 0.5vh);
  font-weight: 600; /*semi bold*/
}

.textual-content h3 {
  font-size: calc(0.6rem + 0.5vh);
  font-weight: 400; /* regular */
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
