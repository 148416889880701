.container {
  background-color: var(--main-colour-2);
  margin-bottom: 1vh;
  margin-top: 1vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.first {
  border: 6px solid var(--reflect-blue-tab-colour);
}

.second {
  border: 6px solid var(--sharing-purple-tab-colour);
}

.third {
  border: 6px solid var(--skills-description-pink-tab-colour);
}

.top {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.bottom {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin-bottom: 20px;
}

.top-and-bottom {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
