@import "../../App.css";

.circle {
  position: relative;
  border-radius: 50%;
  background-color: var(--main-colour-2);
  box-shadow: 0 0 0 10.9px rgba(253, 245, 230, 0.6),
    0 0 0 19.3px rgba(253, 245, 230, 0.5), 0 0 0 27.6px rgba(253, 245, 230, 0.4);
  width: 30vw; /* 30% of the viewport width */
  height: 30vw; /* 30% of the viewport width */
  max-width: 300px; /* Maximum width to prevent it from getting too large */
  max-height: 300px; /* Maximum height to prevent it from getting too large */
  min-height: 300px;
  min-width: 300px;
  margin: 2vw 5vw; /* 5% of the viewport width */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .circle {
    width: 50vw;
    height: 50vw;
    margin: 10vw;
  }
}

/* Adjustments for even smaller screens */
@media (max-width: 480px) {
  .circle {
    width: 70vw;
    height: 70vw;
    margin: 15vw;
  }
}

.circle span {
  color: var(--main-colour);
}

.children {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.bigBubble {
  position: absolute;
  top: -40%;
  left: 115%;
  transform: translate(-115%, 40%);
  margin: 0;
  width: 40%;
  height: 40%;
  opacity: 0.6;
}

.bubble2 {
  position: absolute;
  left: -10%;
  top: 30%;
  transform: translate(10%, -30%);
  margin: 0;
  width: 20%;
  height: 20%;
  opacity: 0.6;
}

.bubble3 {
  position: absolute;
  top: 65%;
  left: -15%;
  transform: translate(-15%, -65%);
  margin: 0;
  width: 30%;
  height: 30%;
  opacity: 0.6;
}

.bubble4 {
  position: absolute;
  top: 101%;
  left: 40%;
  transform: translate(-101%, -40%);
  margin: 0;
  width: 35%;
  height: 35%;
  opacity: 0.6;
}

.bubble5 {
  position: absolute;
  top: 110%;
  left: 42%;
  transform: translate(-42%, -110%);
  margin: 0;
  width: 10%;
  height: 10%;
  opacity: 0.6;
}

.bubble6 {
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translate(-50%, -120%);
  margin: 0;
  width: 12%;
  height: 12%;
  opacity: 0.6;
}

.bubble7 {
  position: absolute;
  top: 105%;
  left: 95%;
  transform: translate(-105%, -95%);
  margin: 0;
  width: 20%;
  height: 20%;
  opacity: 0.6;
}

.bubble8 {
  position: absolute;
  left: 110%;
  top: 20%;
  transform: translate(-110%, -20%);
  margin: 0;
  width: 15%;
  height: 15%;
  opacity: 0.6;
}

.bubble9 {
  position: absolute;
  left: 95%;
  top: 20%;
  transform: translate(-95%, -20%);
  margin: 0;
  width: 5%;
  height: 5%;
  opacity: 0.6;
}

.movingBubble {
  -webkit-animation: animateBubble 12s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 12s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 12s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  left: 60%;
  top: 50%;
  opacity: 0.7;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
  transition: margin-top 0.2s ease-in-out;
}

.movingBubble2 {
  -webkit-animation: animateBubble 21s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 21s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 21s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  left: 0%;
  top: 50%;
  opacity: 0.7;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
  transition: margin-top 0.2s ease-in-out;
}

.movingBubble:active {
  animation: none;
  transform: scale(0.1);
  opacity: 0;
}

@keyframes animateBubble {
  0% {
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    transform: scale(0.3);
    margin-top: 100px;
  }
  40% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
  }
  60% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }
  90% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    transform: scale(1.5);
    margin-top: -120%;
  }
}

@keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}
