.navigation-page {
  background-color: #fdf5e6;
  padding-top: 8vh;
  max-height: 100vh;
  display: flex;
  flex-flow: column;
  width: 100%;
  overflow: hidden;
}

.navtree-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.navtree-container svg {
  position: absolute;
  top: 0;
  left: 8%;
  width: 100%;
  height: 100%;
}

.greetings {
  color: #828080;
  padding-top: 30px;
  margin: 0 auto;
  width: 50%;
  max-width: 500px;
  font-weight: 400;
  white-space: pre-wrap;
}

.mascot-content {
  height: 15vh;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #acda9c;
}

@media screen and (max-width: 767px) {
  .navtree-container {
    padding-bottom: 179%; /* Adjust this value to set the container aspect ratio */
  }

  .navtree-container svg {
    left: -1%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* CSS rules for tablet devices */
  .navtree-container {
    padding-bottom: 200%; /* Adjust this value to set the container aspect ratio */
  }

  .navtree-container svg {
    left: 11%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* CSS rules for laptop devices */
  .navtree-container {
    padding-bottom: 20%; /* Adjust this value to set the container aspect ratio */
  }
}

@media only screen and (min-width: 1200px) {
  /* CSS rules for desktop devices */
  .navtree-container {
    padding-bottom: 68%; /* Adjust this value to set the container aspect ratio */
    overflow: hidden;
  }
}
