.sidebar-account {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 10px 0 10px 30px;
  cursor: pointer;
}

.sidebar-account a {
  text-decoration: none;
}

h4 {
  margin: 0;
  padding-left: 10px;
  color: var(--main-colour);
  font-weight: 700;
}

.accountcircle {
  color: var(--main-colour);
}

.sidebar-account:hover {
  background-color: var(--main-colour);
}

.sidebar-account:hover > a h4 {
  color: var(--main-colour-2);
}

.sidebar-account:hover > .accountcircle {
  color: var(--main-colour-2);
}
