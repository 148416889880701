.search-bar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 1rem;
  color: var(--main-colour);
}

.search-bar-form {
  width: 80%;
}

.search-bar-input {
  border: none;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  padding: 5px 10px;
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 1);
}

.search-bar-input:focus {
  outline: none;
}
