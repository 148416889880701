.container {
  width: 90%;
  position: relative;
}

.resource-plate-container {
  background-color: var(--secondary-colour-2);
  color: var(--main-colour);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
}

.is-open {
  background-color: var(--main-colour);
  color: var(--secondary-colour-2);
}

.resource-drop-down {
  background-color: var(--secondary-colour-2);
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 1);

  min-height: 100px;
  border-radius: 10px;
}
