.header {
  width: 100%;
}

.form {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25%;
}

.checkbox {
  text-align: center;
  min-width: 33px;
}

.policy-notif {
  display: flex;
  color: #828080;
  font-size: 0.8em;
  font-style: italic;
  margin-bottom: 5px;
  min-width: 290px;
  width: 80%;
  gap: 5px;
  justify-content: center;
}

.policy {
  color: var(--secondary-colour);
  text-decoration: underline;
  cursor: pointer;
}
.policy:hover {
  color: #f5a22d;
}

.link {
  color: var(--text-colour);
  font-size: 0.9em;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (orientation: landscape) {
  .create {
    margin: 10px;
  }
}
