.switch-container button {
  background-color: unset;
  box-shadow: unset;
  border-radius: unset;
  padding: unset;
}

.switch-container button:hover {
  background-color: unset;
}

.switch-container span {
  text-transform: none !important;
  color: #ffffff;
}
