.name {
  font-size: 1rem;
  width: 100px;
  text-align: right;
}

.topic {
  font-size: 1rem;
  width: 150px;
}

.selected-topics-title {
  color: var(--secondary-colour-2);
}