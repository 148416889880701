.sidebar {
  position: absolute;
  top: 0%;
  left: -100%;
  border-radius: 0 32px 32px 0;
  border: 3px solid var(--main-colour);
  border-left: none;
  width: 100%;
  max-width: 300px;
  z-index: 10;
  background-color: var(--main-colour-2);
  font-size: 0.9em;
  transition: left 200ms ease-out;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.active {
  left: 0%;
}

.sidebar .border {
  display: flex;
  flex-direction: column;

  height: 100vh;
}

.close {
  margin-top: 6%;
  margin-left: 87%;
  cursor: pointer;
  opacity: 0.8;
}

.close:hover {
  opacity: 1;
}

.sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar li {
  padding: 10px 40px;
  color: var(--main-colour);
  font-weight: 600;
  cursor: pointer;
}

.sidebar li a {
  color: var(--main-colour);
}

.sidebar li:hover a,
.sidebar li:hover button {
  color: var(--main-colour-2);
}

.sidebar li:hover {
  background-color: var(--main-colour);
  color: var(--main-colour-2);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.divider {
  height: 1px;
  width: 75%;
  border-top: 1px solid salmon;
  margin: 2px auto;
}

.logout {
  margin-top: auto;
  align-self: center;
  margin-bottom: 50px;
}

.logout button {
  background-color: inherit;
  border: 2px solid var(--main-colour);
  box-sizing: border-box;
  border-radius: 20px;
  padding: 0.3em 3.4em;
  font: inherit;
  color: var(--main-colour);
  font-weight: 700;
  cursor: pointer;
}

.logout button:hover {
  background-color: var(--main-colour);
  color: var(--main-colour-2);
}

.backdrop {
  position: absolute;
  top: 0%;
  left: -10%;
  width: 200%;
  height: 200vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 5;
}
