.table {
  text-align: center;
  border-collapse: collapse;
}

.table td,
.table th {
  border: 1px solid;
  padding: 2px;
}
