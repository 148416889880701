.card {
  border-radius: 10px;
  perspective: 1000px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* This container is needed to position the front and back side */
.card-inner {
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.card.active .card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.card-front {
  border-radius: 10px;
  background-color: #fff;
  color: black;
}

/* Style the back side */
.card-back {
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  transform: rotateY(-180deg);
}

/* Style the back side */
.card-back p {
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Style the back side */
.card-back img {
  position: absolute;
  right: 2px;
  top: 2px;
}
