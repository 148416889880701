.steps {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 70%;
  align-self: center;
  margin: 0rem auto;
}

.circle {
  outline: none;
  border: none;
  position: relative;
  width: 3.5vh;
  height: 3.5vh;
  border-radius: 50%;
  background-color: #c4c4c4; /* From figma */
}

.circle strong {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: var(--secondary-colour-2);
}
