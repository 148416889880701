@import "../../../App.css";

/* Wrapper to style direction for elements inside */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Animation */
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

/* Direction Style for elements inside form */
.container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--main-colour-2);
  width: 65%;

  max-width: 500px;
}

/* Placeholder disappears on focus */
.container form > .inputField > input:focus::placeholder {
  color: transparent;
}

/* Styling for Terms and Conditions */
.terms {
  display: flex;
  flex-direction: row;
  display: grid;
  grid-template-columns: 0.8em auto;
  gap: 0.5em;

  margin: 1em 0em;
}

/* Hide native checkbox input */
.terms input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;

  width: 0.86em;
  height: 0.86em;
  transform: translate(-0.2em, 0.2em);
  /* Base style for custom checkbox */
  display: grid;
  place-content: center;
  font: inherit;
}

.terms input[type="checkbox"]::before {
  width: 1em;
  height: 1em;
  color: var(--main-colour-2);
  border: 0.15em solid var(--main-colour-2);
  border-radius: 0.01em;
  content: "";
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.terms input[type="checkbox"]:checked::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.termsLabel {
  color: var(--main-colour-2);
  font-size: 1.5vh;
  font-style: italic;
}

.termsLabel > button {
  /* Removes default styling for button */
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  text-decoration: underline;
}

/* Next Button styling based on Figma*/
.submitButton {
  border: none;

  border-radius: 30px;
  margin: 10px;
  color: var(--main-colour-2);
  background-color: var(--main-colour);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
  width: 92%;
  font-size: 1.2em;
}

.text {
  /* position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  margin: 0; */
  font-size: 1.2em;
  color: var(--main-colour);
  text-align: center;
  margin-top: 1em;
  width: 100%;
  padding: 0em 1em;
  margin-top: auto;
  margin-bottom: auto;
}

.avatar {
  /* position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  margin: 0; */
  width: 50%;
  height: 50%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
