@import "../../App.css";

.link {
  text-decoration: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Animation */
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 500px;
}

.container h1,
.container h2 {
  /* Overwrite from Sidebar */
  padding: 0%;

  color: var(--main-colour);
  text-align: center;
}

.container h2 {
  font-size: 1rem;
  font-weight: normal;
}

.container h1 {
  font-size: 3rem;
  line-height: 1;
}

.container em {
  font-size: 3.5rem;
}

.container p {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: var(--main-colour);
  padding: 5px;
  margin: 1.5rem 0rem;
  background-color: var(--main-colour-2);
  padding: 0.5rem 4.5rem;
  box-shadow: 0px 0px 0px 6.5px rgba(253, 245, 230, 0.5);
}

p.prompt {
  background-color: transparent;
  color: var(--main-colour-2);
  box-shadow: none;
}

p.left {
  width: 85%;
  border-radius: 30px 0 0 30px;
  padding: 10px 25px;
  align-self: flex-end;
  text-align: left;
  animation: fromLeft 2s forwards;
  opacity: 0.3;
}

p.right {
  border-radius: 0px 30px 30px 0px;
  align-self: flex-start;
  animation: fromRight 2s forwards;
  opacity: 0.3;
}

p.center {
  border-radius: 30px;
  align-self: center;
  animation: fromBottom 2s forwards;
}

p.centerFill {
  width: 100%;
  padding: 0.5rem;
  opacity: 0.2;
  animation: textAppear 5s forwards;
}

@keyframes textAppear {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@keyframes fromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fromLeft {
  0% {
    transform: translateX(50%);
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fromRight {
  0% {
    transform: translateX(-50%);
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
