@import "App.css";

.relativeContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.bubbleContainer {
  background-color: var(--main-colour);
  background: linear-gradient(
    150deg,
    var(--main-colour) 0%,
    var(--main-colour-80) 25%,
    var(--main-colour) 50%,
    var(--main-colour-80) 75%,
    var(--main-colour) 100%
  );
  border-radius: 20px;
  color: var(--main-colour-2);
  box-shadow: 0px 4px 4px 0px rgba(196, 196, 196, 0.4);
  padding: 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-height: 100%;
  /* max-height: 45vh;  */
  overflow: visible;
  --scroll-bg: rgba(0, 0, 0, 0);
  scrollbar-color: var(--main-colour-2) var(--scroll-bg);
  scrollbar-width: 32px;

  /* Animation */
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

/* Container with alt bg */
.altBubbleContainer {
  /* Bg color */
  background-color: rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.6);

  /* Font colour */
  color: var(--main-colour);
}

/* Arrow for SpeechBubble */
.bubbleContainer .bottomArrow {
  color: var(--main-colour);
  margin: 0;
  width: 20%;
  position: absolute;
  top: 99.7%;
  left: 19.4%;
}

.altBubbleContainer .bottomArrow {
  color: rgba(254, 251, 245, 1);
}

.bubbleContainer > h1 {
  text-align: center;
  /* font-size: min(2.22vh, 2em); */
  font-size: calc(0.6rem + 1vh);
  font-weight: 600;
  margin: 0;
  /* Animation */
  -webkit-animation: fadein 1.2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.2s; /* Firefox < 16 */
  -ms-animation: fadein 1.2s; /* Internet Explorer */
  -o-animation: fadein 1.2s; /* Opera < 12.1 */
  animation: fadein 1.2s;
}

.bubbleContainer * {
  font-size: calc(0.35rem + 1vh);
}

.bubbleContainer * + * {
  margin-top: calc(0.25rem + 1vh);
}

.bubbleContainer ol {
  padding-left: 10%;
  margin: calc(0.5rem + 1vh) 0;
  font-size: calc(0.35rem + 1vh);
}

.bubbleContainer strong {
  font-weight: 500;
}

.bubbleContainer > button {
  position: relative;
  border: none;
  border-radius: 40px;
  background-color: var(--main-colour-2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 35.6%;

  text-align: center;
  color: var(--main-colour);
  font-weight: bold;
  padding: 3% 10% 3% 10%;
  margin-top: 7%;

  -webkit-animation: buttonFadeIn 1.2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: buttonFadeIn 1.2s; /* Firefox < 16 */
  -ms-animation: buttonFadeIn 1.2s; /* Internet Explorer */
  -o-animation: buttonFadeIn 1.2s; /* Opera < 12.1 */
  animation: buttonFadeIn 1.2s;
}

.altBubbleContainer > button {
  background-color: rgba(255, 255, 255, 0.6);
  border: 3px solid var(--main-colour);
}

.glitter {
  position: absolute;
  left: 85%;
  top: -70%;
  margin: 0;
}

.content {
  margin: 0;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 15px;
  /* Animation */
  -webkit-animation: fadein 1.2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.2s; /* Firefox < 16 */
  -ms-animation: fadein 1.2s; /* Internet Explorer */
  -o-animation: fadein 1.2s; /* Opera < 12.1 */
  animation: fadein 1.2s;
}

/* Media query for mobile screens 480px and smaller */
@media (max-width: 480px) {
  .content {
    margin-top: 40px; /* Reduce top margin */
    gap: 10px;
  }
}

/* Media query for mobile screens 412px and smaller */
@media (max-width: 412px) {
  .content {
    margin-top: 50px; /* Reduce top margin */
    gap: 10px;
  }
}

/* Media query for mobile screens 412px and smaller */
@media (max-width: 428px) {
  .content {
    margin-top: 55px; /* Reduce top margin */
    gap: 10px;
  }
}

/* Fade in animation */
/* @keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
} */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes buttonFadeIn {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
