.container {
  background: linear-gradient(
    to bottom right,
    var(--main-colour),
    var(--main-colour-80),
    var(--main-colour),
    var(--main-colour-80),
    var(--main-colour)
  );
}
