.box {
    background: linear-gradient(
      to bottom right,
      var(--main-colour),
      var(--main-colour-80),
      var(--main-colour),
      var(--main-colour-80),
      var(--main-colour)
    );
    border-radius: 25px;
    padding-top: 3.5vh;
    margin-top: 10px;
    padding-bottom: 5vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.circle {
    position: absolute;
    top: 3%;
    right: 3%;
}
