/* --average modifier for sliders in RatingAverage */

.track {
  display: inline-block;
  height: 8px;
  width: 75%;
  margin: 0 12%;
}

.track--average {
  height: calc(0.6rem + 0.5vh);
  position: relative;
}

.tick {
  content: "";
  position: absolute;
  height: 5px;
  width: 2px;
  transform: translate(-50%, 0.7rem);
  bottom: 65px;
}

/* min max icons*/
.tick-label {
  position: absolute;
  font-size: 1rem;
  font-weight: bold;
  color: var(--main-colour-2);
  top: 100%;
  width: 10px;
  transform: translate(-50%, 1.2rem);
  white-space: nowrap;
}

.face {
  position: absolute;
  font-size: 1rem;
  font-weight: bold;
  color: var(--main-colour-2);
  margin-left: 10px;
  transform: translate(-90%, 1.2rem);
  white-space: nowrap;
}

.face--average {
  transform: translate(-90%, 1.5rem);
}

.segment {
  background: var(--main-colour-2);
  border-radius: 25px;
  width: 105%;
  height: 100%;
  box-shadow: 2px 2px #9b9b9b;
  bottom: 16.5px;
  right: -7px;
}

.segment--average {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.125);
  bottom: 0;
  background: var(--main-colour-60);
}

.bar {
  background: var(--secondary-colour);
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  width: 3px;
  height: 1.4rem;
}

.bar-button--average {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar--average {
  background: red;
  height: calc(2rem + 0.5vh);
}

.test-row {
  display: flex;
  flex-direction: row;
}

.handle {
  background: radial-gradient(var(--main-colour-2) 10%, rgba(253, 245, 230, 0));
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
  font-size: 0.7rem;
  white-space: nowrap;
  color: var(--text-colour);
  font-weight: medium;

  transition: all 0.1s linear;
}

.track button:active > .handle {
  transition: all 0.3s linear;
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  /* scale: 1.5;
  -webkit-scale: 1.5; */
  /* transform: scale(1.5);
  -webkit-transform: scale(1.5); */
}

.handle--average {
  color: #fdfaf5;
  background: radial-gradient(var(--main-colour) 10%, rgba(253, 245, 230, 0));
}

.dummy-handle--average {
  visibility: hidden;
}
