.content {
  background: var(--secondary-colour-2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  min-height: 150px;
  width: 95%;
}

.content .header {
  background: var(--main-colour);
  color: var(--secondary-colour-2);
  padding: 5px 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-transform: lowercase;
}

.content .header::first-letter {
  text-transform: uppercase;
}

.content .body {
  padding: 15px 12px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}
