p.prompt {
  font-size: 1rem;
  font-weight: 500;
  background-color: transparent;
  color: var(--main-colour-2);
  box-shadow: none;
}

p.left {
  border-radius: 30px 0 0 30px;
  align-self: flex-end;
}

p.right {
  border-radius: 0px 30px 30px 0px;
  align-self: flex-start;
}

p.center {
  border-radius: 30px;
  align-self: center;
}

p.centerFill {
  width: 100%;
  padding: 0.5rem;
}

p.error {
  font-size: 14px;
  color: var(--text-colour);
  background: var(--main-colour-2);
  margin-top: 1rem;
  border-radius: 45px;
  padding: 1.5rem 2rem;
}

/* Next Button styling based on Figma*/
.submitButton {
  border: none;
  border-radius: 30px;
  margin: 10px;
  margin-top: 5rem;
  color: var(--main-colour-2);
  background-color: var(--main-colour);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
  width: 92%;
  font-size: 1.2em;
  cursor: pointer;
}

.avatar-helper {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.avatar-helper svg {
  width: 35%;
}

.avatar-helper button {
  width: 70%;
  margin: 0 auto;
}
