.topics-container {
  background-color: #fff;
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 1);
  overflow-y: scroll;
  -ms-overflow-style: scrollbar; /* Added for Internet Explorer and Edge */
}

/* Add scrollbar styles for WebKit browsers (Chrome, Safari, Opera, etc.) */
.topics-container::-webkit-scrollbar {
  width: 8px;
}

.topics-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.topics-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.topics-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Add scrollbar styles for Firefox */
@-moz-document url-prefix() {
  .topics-container {
    scrollbar-color: #888 #f1f1f1;
    scrollbar-width: thin;
  }
}

.topic {
  padding: 10px 15px;
  margin: 10px;
  background-color: var(--main-colour-2);
  border-radius: 20px;
  color: var(--main-colour);
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 1);
  height: fit-content;
  cursor: pointer;
}

.topic-selected {
  background-color: var(--main-colour);
}

.topic-selected strong {
  color: var(--secondary-colour-2);
}

.carouselWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  height: var(--carousel-height);
  margin-bottom: 5vh;
}
