.intention-form {
  width: 90%;
}

.text-area textarea {
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 15px 10px 5px 10px;
  border: 0;
  width: 100%;
  border-radius: 20px;
  font-family: "Poppins", "Arial";
  font-size: 15px;
  resize: none;
  position: relative;
}

.button {
  align-self: flex-end;
  text-transform: uppercase;
  margin: 10px 15px;
  float: right;
}

.text-area textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--cloud-colour);
  opacity: 1; /* Firefox */
}

.text-area textarea:focus {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  outline: none !important;
}
