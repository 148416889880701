.container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 0 2rem;
}

.input-container {
  display: flex;
  width: 100%;
}

.input-container .svg-container {
  display: flex;
  align-items: center;
  padding: 5px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: rgba(196, 196, 196, 0.3);
}

.input-container .svg-container svg {
  width: 12px;
}

.input-container input {
  width: 100%;
  background: rgba(196, 196, 196, 0.3);
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  border: none;
  outline: none;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.container button {
  border: none;
  outline: none;
  color: #565454;
  background: none;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 30px;
  cursor: pointer;
}
