@import "../../../App.css";

.link {
  text-decoration: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  max-height: 700px;
  max-width: 500px;
}

.container h1,
.container h2 {
  /* Overwrite from Sidebar */
  padding: 0%;

  color: var(--main-colour);
  text-align: center;
}

.container h2 {
  font-size: 1rem;
  font-weight: normal;
}

.container h1 {
  font-size: 3rem;
  line-height: 1;
}

.container em {
  font-size: 3.5rem;
}

p.prompt {
  font-size: 1.3rem;
  font-weight: 500;
  background-color: transparent;
  color: var(--main-colour-2);
  box-shadow: none;
}

p.left {
  border-radius: 30px 0 0 30px;
  align-self: flex-end;
}

p.right {
  border-radius: 0px 30px 30px 0px;
  align-self: flex-start;
}

p.center {
  border-radius: 30px;
  align-self: center;
}

p.centerFill {
  width: 100%;
  padding: 0.5rem;
}

.input-container {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.input-container input {
  width: 100%;
  border: 0;
  outline: 0;
  background: transparent;
  margin-top: 3rem;
  border-bottom: 7px solid rgba(253, 245, 230, 40);
  font-size: 2.4em;
  line-height: 30px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 12px;
  color: var(--main-colour-2);
}

p.error {
  font-size: 14px;
  color: var(--text-colour);
  background: var(--main-colour-2);
  margin-top: 1rem;
  border-radius: 45px;
  padding: 1.5rem 2rem;
}

/* Next Button styling based on Figma*/
.submitButton {
  border: none;
  border-radius: 30px;
  margin: 10px;
  margin-top: 5rem;
  color: var(--main-colour-2);
  background-color: var(--main-colour);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
  width: 92%;
  font-size: 1.2em;
}
