.other-intention-section {
  background: var(--secondary-colour-2-60);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  max-height: 450px;
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical;
  width: 90%;
}

.other-intention-title {
  padding: 1rem 2rem;
  color: var(--text-colour);
  font-size: 1rem;
  font-weight: 700;
}

.other-intention-entry {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0px;
}
