.role-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 2px solid var(--main-colour);
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.2s linear;
}

.role-container-compressed {
  height: 100vh;
  max-height: 350px;
  transition: all 0.2s linear;
}

.role-selected {
  border: 10px solid var(--secondary-colour);
  border-radius: 20px;
}

.compressed {
  align-self: flex-end;
  position: absolute;
  bottom: 1%;
  right: 1%;
}

.expand {
  align-self: flex-end;
  position: absolute;
  bottom: 0.5%;
  right: 1%;
}
