.scene {
  perspective: 1000px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.role-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 1s;
  transform-style: preserve-3d;
  height: 100%;
  position: relative;
  margin: 5%;
}

.card-face {
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-face-flipped {
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.card-back {
  transform: rotateY(180deg);
}

.is-flipped {
  transform: rotateY(180deg);
}
