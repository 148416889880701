.container {
    background: linear-gradient(
        to bottom right,
        var(--main-colour),
        var(--main-colour-80),
        var(--main-colour),
        var(--main-colour-80),
        var(--main-colour)
        );
    border-radius: 1.5rem;
    padding-top: 3.5vh;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 10px;
    padding-bottom: 5vh;
    width: 100%;
    max-width: 28rem;
    margin-top: 1.5rem;
    height: 22rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.header {
    position: sticky;
    display: grid;
    grid-template-columns: 10% 40% 25% 25%;
    gap: 1%;
    width: 100%;
    justify-items: left;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: bold;
}

.row-container {
    position: relative;
    width: 100%;
    overflow-y: auto;
}

.red-note {
    position: relative;
    color: red;
    font-size: 0.8rem;
}
