.header {
  width: 100%;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.please-enter-email {
  font-size: 0.8em;
  color: var(--text-colour);
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  margin-top: 80%;
}

.divider {
  height: 1.8em;
}

@media (orientation: landscape) {
  .button {
    margin: 20px;
  }
}
