@import "App.css";

.container {
  /* margin: 4vh 0vh; */
  /* Set max-height and max-width */
  /* max-height: 700px; */
  width: 100%;
  height: calc(92vh - 50px);

  /* Display direction with flex */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  position: relative;
}

.information {
  padding: 0% 7.2%;
  width: 100%;
  max-width: 600px;
  /* height: 70%; */
  position: absolute;
  bottom: 4%;
  height: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.children {
  width: 100%;
  max-height: calc(100% - 29vh); /* (25vh + 3vh + 1vh) */
  margin-bottom: 3vh;
}

.larefContainer {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: 0.7fr 1fr;
}

.spacer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 2;
  position: relative;
}

.leftMascot {
  position: absolute;
  bottom: 5%;
  /* left: 30%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.laref {
  align-self: flex-start;
  height: 30vh;
}
