.bar-button {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 10%; /* or some specific value */
  height: auto;
}

.bar-button .avatars {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%; /* or some specific value */
}

/* Default (user's) 
*/
.handle {
  /* background: radial-gradient(var(--main-colour-2) 10%, rgba(253, 245, 230, 0)); */
  border: 2px solid var(--icon-colour-1);
  background-color: var(--icon-colour-1-background);
  color: var(--icon-colour-1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
  font-size: 0.7rem;
  white-space: nowrap;
  /* position: absolute;
  top: -65%;
  left: -65%; */
}

.bar {
  background: var(--icon-colour-1);
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  width: 3px;
  /* height: 1.4rem; */
  height: calc(2rem + 0.5vh);
}

.avatars--hidden {
  visibility: hidden;
}
