.main-container {
  font-family: Arial, sans-serif;
  background-color: #f9f1ef;
  display: flex;
  justify-content: center;
  padding: 20px;
  overflow-y: auto;
  /* Allows vertical scrolling */
  height: 100%;
  /* Ensures it takes up the full page height */
  max-height: 100vh;
  /* Limit to viewport height */
  -webkit-overflow-scrolling: touch;
}

.form-container {
  width: 100%;
  max-width: 800px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: auto;
  max-height: 100vh;
  /* Limits the form height to the viewport */
  overflow-y: auto;
  /* Enables scrolling if form is taller than viewport */
}

.form-header {
  text-align: center;
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.tab-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@keyframes fadeBorder {
  0% {
    border: 1px solid black;
  }

  100% {
    border: 1px solid transparent;
  }
}

.tab {
  flex: 1;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  color: #555;
  margin: 0 2px;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  background-color: #fdd6d6;
  font-family: 'Arial Rounded MT Bold', Arial, sans-serif;
  font-size: 1em;
  transition: all 0.5s ease-in-out;
  border: 2px solid transparent;
}

.tab.active {
  background-color: #eb8181;
  color: white;
  animation: fadeBorder 0.5s ease;
}

.tab.active:hover {
  background-color: #eb8181;
  color: white;
  /* Keep text color white on hover */
}

.tab:hover {
  background-color: #eb8181;
  color: #333;
}

.section {
  display: none;
}

.section.active {
  display: block;
}

.question-container {
  margin-bottom: 20px;
}

.question-label {
  display: block;
  font-weight: bold;
  margin-bottom: 12px;
  color: #555;
}

.input-text,
.input-textarea,
.input-select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1em;
  box-sizing: border-box;
  position: relative;
  margin-top: 16px;
  margin-bottom: 20px;
}

.input-textarea {
  min-height: 80px;
  resize: vertical;
}

.textarea-container {
  position: relative;
  margin-bottom: 16px;
}

.placeholder-label {
  position: absolute;
  top: 8px;
  left: 12px;
  color: #aaa;
  pointer-events: none;
  transition: 0.2s ease all;
}

.input-textarea:focus+.placeholder-label,
.input-textarea:not(:placeholder-shown)+.placeholder-label {
  top: -10px;
  left: 10px;
  font-size: 12px;
  color: #333;
}

.rating-container {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.rating-item {
  font-size: 1.5em;
  cursor: pointer;
  color: #ddd;
  transition: color 0.3s;
}

.rating-item.selected {
  color: #ffcc00;
}

.file-upload-container {
  margin-top: 10px;
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

}

.top-buttons,
.bottom-buttons {
  display: flex;
  width: 80%;
  justify-content: center;
}

.top-buttons .back-button,
.top-buttons .next-button,
.bottom-buttons .exit-button,
.bottom-buttons .submit-button {
  flex: 1;
  padding: 15px 0;
  /* Adjust padding for consistent look */
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  border: 1px solid #ddd;
  /* Add border for outline */
  border-radius: 0;
  /* Remove border radius for inner edges */
  background-color: #f7f7f7;
}

.top-buttons .back-button {
  flex: 1;
  padding: 15px 0;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 30px;
  background-color: #eee;
  color: #666;
}

.top-buttons .next-button {
  flex: 1;
  padding: 15px 0;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 30px;
  background-color: #eb8181;
  color: white;
}

.bottom-buttons .exit-button {
  border-radius: 20px 0 0 20px;
  /* Rounded left edge */
  background-color: #ff4d4d;
  color: white;
}

.bottom-buttons .submit-button {
  border-radius: 0 20px 20px 0;
  /* Rounded right edge */
  background-color: #8cfca8;
  color: #333;
}

.button-group .top-buttons {
  margin-bottom: 20px;
  /* Space between top and bottom rows */
}

.button {
  flex: 1;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 4px;
  flex: 1;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
}

.next-button:hover {
  background-color: var(--main-colour-80)
}

.back-button {
  background-color: #eee;
  color: #666;
}

.back-button:hover {
  background-color: #aaa;
}

.submit-button,
.exit-button {
  background-color: #8cdb80;
  color: #333;
}

.submit-button:hover {
  background-color: #c3fdd5;
}

.exit-button {
  background-color: #de4141;
  color: white;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #e60000;
}

.file-upload-label {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
  margin-bottom: 40px;
}

.file-upload-label:hover {
  background-color: #0056b3;
}

.exit-button {
  background-color: #ff4d4d;
  /* Red background */
  color: white;
  /* White text */
  border: none;
  /* No border */
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  /* Pointer on hover */
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.exit-button:hover {
  background-color: #ff1a1a;
  /* Darker red on hover */
}

.cancel-button {
  background-color: #eb7a7a;
  color: #333;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 5px;
  margin-top: 5px;
}

.cancel-button:hover {
  background-color: #d9534f;
  /* Color on hover */
  color: white;
  /* Text color on hover */
}

/* Modal Buttons Container */
.modal-buttons-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  /* Center the buttons horizontally */
  width: 100%;
}

/* Modal Overlay */
.modal-overlay {
  display: none;
  /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Ensure modal overlay is displayed and centered when active */
.modal-overlay.active {
  display: flex;
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* Modal Header */
.modal-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Modal Button Styles */
.modal-button {
  padding: 10px 20px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

}

.return-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover and active styles for extra interactivity */
.return-button:hover {
  background-color: #45a049;
  /* Darker green on hover */
}

.return-button:active {
  background-color: #3e8e41;
  /* Even darker green when clicked */
  transform: scale(0.98);
  /* Effect on click */
}

@media (max-width: 600px) {
  .form-container {
    padding: 15px;
    font-size: 0.9rem;
  }
}

.missing-fields-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.missing-fields-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 400px;
  text-align: center;
}

.missing-fields-content h2 {
  margin-top: 0;
  font-size: 1.5em;
  color: #333;
  padding-bottom: 4px;
  border-bottom: 2px solid #ddd;
}

.missing-fields-content p {
  margin: 15px 0;
  color: #666;
}

.missing-fields-close-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.missing-fields-close-button:hover {
  background-color: #0056b3;
}

.invalid-file-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it is on top of other content */
}

.invalid-file-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 300px;
  text-align: center;
  animation: fadeIn 0.3s ease;
  /* Smooth fade-in effect */
}

.invalid-file-content h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #e74c3c;
  /* Red color for error */
}

.invalid-file-content p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #333;
}

.file-modal-button {
  background-color: #e74c3c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.file-modal-button:hover {
  background-color: #c0392b;
  /* Darker red on hover */
}

.file-modal-button {
  padding: 8px 16px;
  /* Adjust button padding for smaller screens */
}

.submit-button:disabled,
.cancel-button:disabled {
  background-color: #cccccc;
  /* Grey out button when disabled */
  color: #888888;
  /* Text color when disabled */
  cursor: not-allowed;
  /* Change cursor to indicate disabled state */
}

/* Disable hover effect when button is disabled */
.submit-button:disabled:hover,
.cancel-button:disabled:hover {
  background-color: #cccccc;
  /* Keep the disabled color on hover */
}