.tab-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-top: 25px;
  min-height: 8vh;
  width: 100%;
  -moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none; -o-user-select:none;
}

.tab {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 20px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  text-align: center;
}

.tab-border {
  box-sizing: border-box;
  border: 6px solid var(--choose-share-bg-colour);
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 2;
}

.first-tab {
  background-color: var(--reflect-blue-tab-colour);
}

.second-tab {
  background-color: var(--sharing-purple-tab-colour);
}

.third-tab {
  background-color: var(--skills-description-pink-tab-colour);
  opacity: 50%;
}

.activated {
  height: 40px;
}
