@import "../../../App.css";

.modalStyle {
  width: 280px;
  max-width: 300px;
  border-radius: 20px;
  display: flex;
  flex-flow: row;
  margin: 0 auto;
  left: 50%;
  top: -20%;
  padding: 5px;
  opacity: 0;
  animation: appear 1s forwards;
}

@keyframes appear {
  from {
    opacity: 0;
    top: -20%;
  }
  to {
    opacity: 1;
    top: 20%;
  }
}

.stepContainer {
  width: 45px;
  background: var(--page-number-colour);
  color: var(--main-colour-2);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8em;
}

.contentStyle {
  display: flex;
  flex-flow: column;
  flex: 1;
  padding: 5px 13px 10px;
  font-size: 0.9em;
  gap: 0.5em;
}

.contentTitle {
  font-size: 1.2em;
  font-weight: 700;
  color: var(--text-colour);
}

.contentDetails {
  display: flex;
  flex-flow: row;
  color: var(--text-colour);
  gap: 0.5em;
}

.iconContainer {
  width: 24px;
  height: 24px;
  background-color: var(--main-colour);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.closeButton {
  cursor: pointer;
}
