@import "../../App.css";

:root {
  --carousel-height: auto;
}

.carouselContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carouselWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  height: var(--carousel-height);
}

.carouselContentWrapper {
  overflow: hidden;
  /* height: 100%;  remove to center vertically and horizontally*/
  height: 100%;
  width: var(--carousel-width);
  margin: auto;
}

.carouselContent {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
  scrollbar-width: none; /* hide scrollbar in Firefox */
  height: 100%;
  width: calc(100% / var(--carousel-display));
}

.carouselContent--vertical {
  width: 100%;
  height: calc(100% / var(--carousel-display));
}

.leftArrow,
.rightArrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 10%;
  height: 100%;
  border-radius: 24px;
  background-color: transparent;
  border: none;
  color: var(--main-colour);
}

.leftArrow {
  left: var(--carousel-arrow-offset);
}

.rightArrow {
  right: var(--carousel-arrow-offset);
}

/* hide scrollbar in webkit browser */
.carouselcontent::-webkit-scrollbar,
.carouselcontent::-webkit-scrollbar {
  display: none;
}

.carouselContent > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}
