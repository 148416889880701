.timer-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.timer-input {
  border: none;
  font-size: calc(2em + 1vmin);
  text-align: center;
}

.timer-units {
  font-size: calc(1.5em + 1vmin);
}

.colon {
  font-size: calc(1.5em + 1vmin);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
