.title {
  font-size: 1.5rem;
}

.instructions {
  font-size: .8rem
}

.title, .instructions {
  color: var(--secondary-colour-2);
}

.skill-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
