.role-back-container {
  width: 100%;
  height: 350px;
  border: 2px solid var(--main-colour);
  background-color: var(--secondary-colour-2-60);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  overflow-y: auto;
  position: sticky;
}

.role-selected {
  border: 10px solid var(--secondary-colour);
  border-radius: 10px;
}

.role-back-container p {
  font-size: 1rem;
}

.flip-button {
  border: none;
  background-color: none;
  cursor: pointer;
  transform: scale(1.3);
  position: fixed;
  top: 20px;
  right: 15px;
  height: fit-content;
  width: fit-content;
}

.flip-button svg{
  position: relative;
}


.line {
  width: 100%;
  margin: 15px 0px;
  border-top: 2px solid var(--main-colour);
}
