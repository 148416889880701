@import "../../App.css";

.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* Added to horizontally align items */
  align-items: center;
  background-color: var(--main-colour-2);
}

.forSticky {
  /* Child elements that have position: sticky will not work if overflow-y of parent is anything other than visible */
  overflow-y: visible;
}

/* bgColor changed to pre-session */
.welcome,
.waiting-area {
  background-color: var(
    --main-colour
  ); /* If browser does not support gradient */
  background: var(--main-colour-gradient);
}

.groundRules {
  scrollbar-width: none; /* To hide scrollbar in moz */
  position: relative;
  /* Calculation from figma
  x: (x value in figma) / (width of container - width of x)
  y: (y value in figma) / (width of container - width of y) */
  background-image: url(/src/assets/LeftCloud.svg),
    url(/src/assets/RightCloud.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: 14% 13.3%, 76.8% 16.3%;
}

.grass {
  position: absolute;
  bottom: 0%;
  z-index: 1;
  width: 100%;
  background-image: url(/src/assets/Grass.svg);
  background-repeat: repeat no-repeat;
  background-position: 50% 100%;
  height: 14.5vh;
  margin: 0;
  background-size: contain;
  overflow-y: visible;
}

.grass::after {
  position: absolute;
  content: "";
  bottom: 0%;
  height: 60%;
  width: 100%;
  background-color: var(--grass-colour);
}
