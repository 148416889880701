.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.title {
    font-size: larger;
    font-weight: bold;
    color: var(--main-colour);
}

.legend {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    color: var(--main-color);
}

.Circle {
    position: relative;
    margin-right: -8%;
}

.slider-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    color: var(--main-color);
}

.flag-red {
    color: red;
}

.name-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4%;
}

.red-note {
    position: relative;
    color: red;
    display: flex;
    justify-content: center;
    font-size: small;
    width: 100%;
}
