.container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    margin: 2%;
    border: none;
    display: flex;
    justify-content: center;
}

.container iframe {
    position: absolute;
    width: 95%;
    height: 95%;
    border: none;
}
