.option-container button {
  background-color: unset;
  box-shadow: unset;
  border-radius: unset;
  padding: unset;
}

.option-container button:hover {
  background-color: unset;
}

.option-container span {
  text-transform: none !important;
  color: var(--text-colour);
}
