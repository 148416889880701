.lock-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--main-colour);
  height: 350px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  padding: 30px;
  overflow: auto;
  margin: 5%;
}

.lock-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: 15px;
  padding: 0 1rem;
  right: 0;
}

.role {
  color: var(--main-colour-2);
  font-size: 1.5rem;
  letter-spacing: 2px;
  text-align: center;
}
