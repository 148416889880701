.clouds {
  bottom: 75px;
  position: relative;
}

.clouds .blcloud {
  position: absolute;
  height: 124px;
  width: 126px;
  left: -200px;
  top: 167px;
}

.clouds .brcloud {
  position: absolute;
  height: 133px;
  width: 126.99961853027344px;
  left: 60px;
  top: 197px;
}

.clouds .mcloud {
  position: absolute;
  height: 80px;
  width: 69.00061798095703px;
  top: 138px;
}

.clouds .tlcloud {
  position: absolute;
  height: 118px;
  width: 156.80426025390625px;
  left: -180px;
  top: 49px;
  border-radius: 30px;
}

.clouds .trcloud {
  position: absolute;
  height: 101px;
  width: 104px;
  left: 65px;
  top: 60px;
  border-radius: 30px;
}

.main {
  top: 200px;
  position: relative;
}

.main .title {
  font-size: 75px;
  font-weight: 600;
  color: var(--main-colour);
  left: -75px;
  line-height: 20px;
  position: absolute;
  text-align: center;
}

.main .subtitle {
  color: var(--main-colour);
  top: 40px;
  left: -77px;
  width: 150px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  position: absolute;
  text-align: center;
}

.main .leref {
  position: absolute;
  top: 90px;
  left: -87px;
}

.main .description {
  color: var(--main-colour);
  top: 120px;
  left: -97px;
  width: 190px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  text-align: center;
}
