@tailwind base;
@tailwind components;
@tailwind utilities;

a.active li {
  background-color: #fdb0ae !important;
  color: black !important;
}

.break-underscore {
  word-break: break-all;
}

input,
select,
button {
  --tw-ring-shadow: 0 0 #000 !important;
}

input[type="file"]::file-selector-button {
  background-color: var(--icon-colour-0) !important;
}
