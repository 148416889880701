.chat-users-container {
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  overflow-y: auto;
  padding-bottom: 5rem;
}

.user-container {
  width: 100%;
  max-width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin: 1.5rem 0;
}
.user-container img {
  width: 50px;
  height: 50px;
  border-radius: 40px;
}

.name-container {
  width: 100%;
  padding: 0 1rem;
}

.name-container span {
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.name-container button {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: none;
  outline: none;
  margin-right: 5px;
}

.name-container button.online {
  background: #7ac95f;
}
