.tab > button {
  border-radius: 15px 15px 0px 0px;
  background-color: var(--main-colour-60);
  border: 2px solid var(--main-colour);
  color: #565454;
  padding: 10px;
}

.tab > div > div {
  padding: 0px;
}

.tab > button:hover {
  background-color: var(--main-colour);
}

.tab > button[aria-selected="true"] {
  background-color: var(--main-colour);
  border: 2px solid var(--main-colour);
}
