/* .placeholder {
    height: 100%;
    flex: 1;
    overflow-y: auto;
  }
  
  .placeholder-no-scroll {
    height: 100%;
    flex: 1;
    overflow-y: hidden;
  } */

.messages {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
}

.chat-container {
  width: 100%;
  height: 300px;
  max-height: 300px;
  /* flex: 1; */
  overflow-y: scroll;
}

/* Custom scrollbar styling */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 6px;
  -webkit-appearance: none;
}
