.table {
  font-family: "Poppins";
  border-collapse: collapse;
  width: 100%;
}

.table td,
.table th {
  border: 1px solid #ddd;
  padding: 8px;
  min-width: 80px;
}

.table td svg {
  max-width: 100%; /* Ensure the SVG doesn't overflow the td */
  max-height: 100%; /* Ensure the SVG doesn't overflow the td */
  display: block; /* Remove extra space below the inline SVG */
  margin: auto; /* Center the SVG horizontally */
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}
