.input-placeholder {
  position: relative;
  width: 264px;
  height: 48px;
  background: var(--secondary-colour-2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 4px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.input {
  position: relative;
  border: none !important;
  outline: none;
  color: #565454;
  font-size: 1.1em;
  width: 100%;
  flex-grow: 1;
}

.input:focus {
  box-shadow: none !important;
}

input::placeholder {
  color: var(--main-colour);
  font-weight: 500;
  opacity: 0.4 !important;
}

.alert-placeholder {
  width: 25px;
  position: relative;
  text-align: center;
}

.error {
  border: 2px solid #e71010;
}

.password {
  overflow: visible;
  position: absolute;
  right: 18%;
  top: 12px;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.file {
  cursor: pointer;
}
