.container {
    background-color: var(--main-colour-2);
    border-radius: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    display: grid;
    grid-template-columns: 10% 40% 25% 25%;
    gap: 1%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    position: relative;
    align-items: center;
}

.picture {
    position: relative;
    justify-self: flex-start;
    align-self: flex-start;
    left: -40%;
}
