.title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */
  text-align: center;
  color: var(--text-colour);
  margin-bottom: 1rem;
}


