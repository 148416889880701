.tab > button {
  border-radius: 15px 15px 0px 0px;
  background-color: #ffcaca;
  color: #565454;
  padding: 10px 15px;
  margin: 2px;
  box-shadow: none !important;
  text-transform: unset !important;
  font-weight: 700;
  transition: ease-in all 0.5s;
}

.tab > div > div {
  padding: 0px;
}

.tab > button:hover {
  background-color: #eb8181;
}

.tab > button[aria-selected="true"] {
  background-color: #eb8181;
  border: 2px solid #eb8181;
  color: #ffffff;
}
