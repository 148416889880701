/* top half of skill */
.role-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 2px solid var(--main-colour);
}

.role-header:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 1px;
  z-index: -1;
  transform: scale(0.9);
  -webkit-box-shadow: 0px 0px 8px 2px #000000;
  -moz-box-shadow: 0px 0px 8px 2px #000000;
  box-shadow: 0px 0px 8px 2px #000000;
}

.role-header strong {
  color: var(--main-colour-2);
}

.role-header-inner {
  display: flex;
  width: 100%;
}

.role-icon {
  height: inherit;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-colour-2);
}

.progress-bar-container {
  width: 100%;
  cursor: pointer;
}

/* name info and stats combined */
.role-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

/* name of skill container */
.role-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--main-colour);
  padding: 10px 15px;
  width: 100%;
  font-size: 1.2rem;
}

/* info icon container */
.role-name button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0px;
  height: fit-content;
  width: fit-content;
  transform: scale(1.2);
}

/* container for level and played */
.role-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-left: 2px solid var(--main-colour);
  width: 100%;
  background-color: var(--secondary-colour-2-60);
}

/* the word level: */
.level {
  background-color: var(--level-progress-colour);
  padding: 3px 15px;
  border-radius: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 10px;
  font-size: calc(0.7em + 1vmin);
}

/* the word played: */
.played {
  background-color: var(--num-times-played-progress-colour);
  padding: 3px 15px;
  border-radius: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: calc(0.7em + 1vmin);
}
