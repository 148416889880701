.chats-container {
  max-width: 325px;
  background: #fff;
  max-height: 100vh;
  height: 70vh;
  width: 100%;
  margin-top: 5rem;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1rem;
  overflow: hidden;
  box-sizing: border-box;
}

.line-separator {
  border: 2px solid #eb8181;
  margin: 1rem;
}

.menu-container {
  display: flex;
  width: 100%;
  max-width: 320px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left: -160px;
  margin-bottom: 1rem;
  text-align: center;
  z-index: 1000;
}

.menu-container .menu-item {
  background-color: #fff;
  padding: 1rem;
  width: 95px;
  height: 60px;
  border-radius: 40px;
  background: #ffffff;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in-out;
}

.menu-container .menu-item:hover {
  background-color: #eb8181;
  cursor: pointer;
}

.menu-container .menu-item:hover svg {
  color: #fff;
  filter: brightness(0) invert(1);
}

.menu-container .menu-item svg {
  width: 24px;
  height: 24px;
}
