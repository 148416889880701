@keyframes sway {
  0% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(8deg);
  }
}

.leafcw1 {
  transform-origin: bottom right;
  transform-box: fill-box;
  animation: sway 2s ease-in-out infinite alternate;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.leafcw2 {
  transform-origin: bottom left;
  transform-box: fill-box;
  animation: sway 2s ease-in-out infinite alternate;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.leafcw3 {
  transform-origin: left;
  transform-box: fill-box;
  animation: sway 2s ease-in-out infinite alternate;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.leafcw4 {
  transform-origin: bottom;
  transform-box: fill-box;
  animation: sway 2s ease-in-out infinite alternate;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.leafcw5 {
  transform-origin: right;
  transform-box: fill-box;
  animation: sway 2s ease-in-out infinite alternate;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.leafcw6 {
  transform-origin: bottom left;
  transform-box: fill-box;
  animation: sway 2s ease-in-out infinite alternate;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
