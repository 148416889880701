@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  touch-action: manipulation;
}

html {
  font-family: "Poppins", sans-serif !important;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
  color-scheme: light !important;
}

:root {
  --carousel-display: 3;
  --carousel-width: 75%;
  --carousel-arrow-offset: 5%;
  --carousel-arrow-color: "currentColor";
  --chat-bubble-color: rgb(240, 240, 240);
  --chat-dropdown-bg-color: rgb(250, 250, 250);
  --chat-dropdown-button-hover-bg-color: rgb(190, 190, 190);
  --chat-text-color: #504f4f;
  --chat-name-color: rgb(15, 15, 15);
  --community-page-color: #acda9c;
  --chatbox-background-color: #cee9c4;
  color-scheme: light only !important;
}

:root {
  --main-colour: #eb8181;
  --main-colour-gradient: linear-gradient(
    150deg,
    var(--main-colour) 0%,
    var(--main-colour) 15%,
    var(--main-colour-60) 40%,
    var(--main-colour) 65%,
    var(--main-colour-60)
  );
  --main-colour-80: #ef9895;
  --main-colour-60: #f2afa9;
  --main-colour-20: #f9ded2;
  --main-colour-40: #eb818166;
  --main-colour-50: #eb818180;
  --main-colour-0: #eb818100;
  --main-colour-2: #fdf5e6;
  --main-colour-2-60: #fae3d6;
  --main-colour-2-50: #f7d7cc;
  --main-colour-2-40: #f6cbc1;
  --secondary-colour: #f9bb62;
  --secondary-colour-2: #ffffff;
  --secondary-colour-2-60: #fefbf5;
  --grass-colour: #acdb9c;
  --sky-colour: #cff4f7;
  --cloud-colour: #c4c4c4;
  --text-colour: #565454;
  --text-colour-60: #565454;

  /* trigger */
  --sos-button-colour: #ffa92e;
  --sos-question-row-colour: rgba(249, 187, 98, 0.4);
  --let-facil-know-button-colour: #b0cd00;

  /* dice */
  --number-chosen-icon: rgb(104, 154, 30);

  /* choose roles / choose and share */
  --choose-share-bg-colour: #ae927c;
  --reflect-blue-tab-colour: #a2d7ea;
  --sharing-purple-tab-colour: #dbc6ff;
  --skills-description-pink-tab-colour: #ffa5a5;
  --level-progress-colour: #ae85fd;
  --num-times-played-progress-colour: #c4c4c4;
  --progress-bar-color: #ffa729; /*done*/
  --progress-bar-bg-color: #565454; /*done*/
  --card-background: #ffffff;
  --icon-colour-0: #eb8181;
  --icon-colour-0-background: #f9ded2;
  --icon-colour-1: #ae009d;
  --icon-colour-1-background: #edc4d7;
  --icon-colour-2: #24a400;
  --icon-colour-2-background: #d1e5b8;
  --icon-colour-3: #d38007;
  --icon-colour-3-background: #f4deb9;
  --icon-colour-4: #a44f00;
  --icon-colour-4-background: #ebd4b8;
  --icon-colour-5: #5c6dff;
  --icon-colour-5-background: #dcdaeb;

  --page-number-colour: #b0cd00;

  /* community */
  --typing-box-placeholder: black;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border: none;
  background-color: var(--bg-color);
  -webkit-text-fill-color: var(--main-colour);
}

::-webkit-scrollbar {
  width: 0px;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: var(--main-colour-2);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea,
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
}

[class$="-control"] [style*="overflow: hidden;"] {
  min-width: 150px !important;
  padding: 5px !important;
}

[class$="-multiValue"] {
  background-color: #eb8181 !important;
  padding: 4px !important;
  width: 100% !important;
  border-radius: 30px !important;
  margin-right: 10px !important;
  display: flex;
  justify-content: center;
}

[class$="-multiValue"] > div {
  color: #fdf5e6 !important;
  font-size: 18px !important;
}

[class$="-multiValue"] > div > div {
  color: #fdf5e6 !important;
}

[class$="-multiValue"] > div > svg {
  width: 24px !important;
  height: 24px !important;
}

[id$="-flowbite-toggleswitch"][aria-checked="true"] .toggle-bg {
  background-color: var(--icon-colour-0) !important;
  border-color: var(--icon-colour-0) !important;
}

@media (max-width: 767px) {
  #pdfContainer .react-pdf__Page > canvas {
    width: 100% !important;
    height: 500px !important;
  }
}

.temporary-bounce {
  position: relative; /* Ensure the element stays in place during animation */
  animation: 2s bounceAndWiggle ease infinite;
}

@keyframes bounceAndWiggle {
  0%,
  100% {
    transform: translateY(0) rotate(0deg); /* Start and end position */
  }
  50% {
    transform: translateY(-20px) rotate(5deg); /* Peak of the bounce and wiggle */
  }
}

button.react-datepicker__navigation {
  background: unset !important;
  box-shadow: unset !important;
  padding: 0 !important;
  margin: 0 !important;
}

button.react-datepicker__navigation > .react-datepicker__navigation-icon {
  margin-top: 10px !important;
}

.rdw-image-modal-btn {
  box-shadow: unset !important;
  padding: unset !important;
}
