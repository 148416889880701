.other-role-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.other-role {
  font-size: 1rem;
}

.other-roles-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
