.header-container {
  width: 100%;
  min-height: 184px;
  background-color: white;
  background-position: bottom;
  box-shadow: 0px 2px 2px grey;
  margin-top: calc(1.2em + 2%);
}

.profile-main-container {
  padding-top: 72px;
  padding-left: 8px;
}

.profile-name-container {
  display: flex;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  justify-content: space-between;
}

.profile-name-image {
  flex: 1;
  display: flex;
  align-items: center;
  text-align: left;
}

.profile-name-image > div {
  margin-right: 10px;
}

.circles-joined {
  color: #eb8181;
}

.profile-picture-select {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.nav-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}

.button {
  background-color: var(--main-colour);
  color: var(--main-colour-2);
}

.error {
  align-self: flex-end;
  color: #eb8181;
  font-size: small;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.modal div {
  text-align: center;
}

.center-text {
  max-width: 400px;
  padding: 8px;
  text-align: center;
}

@media (max-width: 499px) {
  .header-container {
    height: 20vh;
    padding: 0 1rem;
  }
}
