.content-container {
  max-height: 700px;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  overflow-y: auto;
  flex: 1;
}

.has-stepper-container {
  margin: 0 auto;
  margin-top: calc(8vh + 60px);
  width: 100%;
  max-width: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  color: var(--text-colour);
  flex: 1 1 auto;
}
