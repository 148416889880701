.carousel-container {
  width: 90%;
  background-color: var(--main-colour);
  border-radius: 20px;
}

.topic-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
}

.topic-card strong {
  color: var(--main-colour-2);
}

.back-and-next {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.back-and-next button {
  background-color: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 1);
}

.back-and-next strong {
  color: var(--main-colour);
  font-size: 1rem;
}

.form {
  width: 90%;
}

.input {
  border: none;
  padding: 15px 20px;
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 1);
  border-radius: 20px;
  width: 100%;
  font-size: 1rem;
}

.input::placeholder {
  color: var(--main-colour);
  opacity: 1;
}
