.title {
  color: var(--main-colour);
  margin-bottom: 5px;
}

.question {
  font-size: 0.9rem;
}

.reflection-text-area {
  white-space: pre-line;
}

.reflection-text-area textarea {
  font-family: "Poppins", serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

ol.list-marker > li::marker {
  font-weight: bold;
}
