.widget-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  /* Added to horizontally align items */
  align-items: center;
  justify-content: center;
  background-color: var(--main-colour-2);
}

.widget-container > h1 {
  font-weight: bold;
  font-size: medium;
  color: var(--text-colour);
  padding: 2rem;
}

.c1 {
  position: absolute;
  /* transform: translate(-50%, -50%); */
  width: 325px;
  height: 325px;
  border-radius: 50%;
  /* background-color: #eb818166; */
  background-color: var(--main-colour-40);
  position: relative;
  z-index: 70000;
  animation: c1 2s infinite;
  /* visibility: hidden; */
}

.c2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  border-radius: 50%;
  /* background-color: #eb818180; */
  background-color: var(--main-colour-50);
  z-index: 80000;
  animation: c2 2s infinite;
  /* visibility: hidden; */
}

.c3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 275px;
  height: 275px;
  border-radius: 50%;
  /* background-color: #eb818199; */
  background-color: var(--main-colour-60);
  z-index: 90000;
  animation: c3 2s infinite;
}

.c4 {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.c4-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: var(--main-colour);
  z-index: 10000000;
}

.loading-text {
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--main-colour-2);
}
.loading-text .progress {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 3.2em;
  line-height: 0;
}

.placeholder {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: auto; */
  /* margin-top: 40%; */
  width: 325px;
  aspect-ratio: 1 / 1;
}

.left-leaf {
  animation: c3 2s infinite;
}
.center-leaf {
  animation: c2 2s infinite;
}
.right-leaf {
  animation: c1 2s infinite;
}

@keyframes c3 {
  0%,
  25% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;
  }
}
@keyframes c2 {
  0%,
  50% {
    opacity: 0;
  }
  75%,
  100% {
    opacity: 1;
  }
}
@keyframes c1 {
  0%,
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spinning {
  0% {
    transform: rotate(0deg);
    /* transform: translate(-50%, -50%); */
  }
  100% {
    transform: rotate(360deg);
  }
}
