.wrapper {
  width: 100%;
  max-width: 500px;
}

h1 {
  font-size: 1rem;
  text-align: center;
}
.choose-roles-content {
  width: 100%;
}

.roles-container {
  width: 100%;
  height: 45vh;
  overflow: scroll;
}
