.container {
  display: flex;
  height: auto;
}

.container .content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 0.2rem;
}

.container .content table {
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
}

.container .content table td,
.container .content table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.container .content table th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: #eb8181;
  color: var(--secondary-colour-2);
  font-weight: 400;
}

.container .content table tr {
  cursor: pointer;
}

.container .content ul {
  padding: 0;
  list-style: none;
}

.main {
  width: 100%;
  padding: 1rem 2rem;
  max-height: 100vh;
  margin-bottom: 50px;
  overflow-y: auto;
}

.main .title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 5vh;
  padding: 0.5rem 0rem;
  padding-right: 2rem;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.main .title .add_new {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 110px;
}

.main .limit-option {
  margin-top: 1rem;
  position: relative;
  background: #fff;
  width: 100px;
  padding: 0.2rem 1rem;
  border: 1px solid rgba(107, 105, 105, 0.2);
  cursor: pointer;
  user-select: none;
}

.main .limit-option .limit-option-list {
  position: absolute;
  background: #fff;
  top: 120%;
  left: 5%;
  background: #fff;
  z-index: 10000;
  width: 200px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.main .limit-option .limit-option-list ul {
  padding: 0;
  list-style: none;
}

.main .limit-option .limit-option-list ul li {
  padding: 0.5rem 1rem;
  width: 100%;
}

.main .limit-option .limit-option-list ul li:hover {
  background: #bbbaba;
  cursor: pointer;
  color: azure;
}

.title h4 {
  color: #6b6969;
  font-size: 1.5em;
}

.container h3 {
  color: #6b6969;
  padding: 0.5rem 0;
}

.sessions {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background: #fff;
}

.sessions td,
.sessions th {
  border: 1px solid #ddd;
  padding: 8px;
}

.sessions tr:nth-child(even) {
  background-color: #f2f2f2;
}

.sessions tr:hover {
  background-color: #ddd;
}

.sessions th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--main-colour);
  color: var(--secondary-colour-2);
}

.table-container {
  width: 100%;
}

form label {
  color: var(--main-colour);
}

form label,
form input,
form textarea {
  display: block;
}

.form-container {
  width: 60%;
  margin: 2rem auto;
  padding: 1rem;
}

.card-container {
  margin: 1rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  gap: 12px;
}

.container label {
  font-size: 0.8em;
  color: #6b6969;
}

.container input {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  width: 100%;
  height: 40px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 0.5rem;
  font-size: 1.2em;
}

.container input[type="range"] {
  margin-top: 0.5em !important;
  margin-bottom: 0 !important;
}

/* Next Button styling based on Figma*/
.container button {
  border: none;
  border-radius: 10px;
  color: var(--main-colour-2);
  background-color: #eb8181;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 15px;
  font-size: 1em;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.container button:hover {
  background-color: var(--main-colour-80);
}

.container button:active {
  box-shadow: none;
}

.container .button {
  border: none;
  border-radius: 30px !important;
  color: var(--main-colour-2);
  background-color: #eb8181;
  box-shadow: unset !important;
  padding: 8px 20px !important;
  font-size: 1.2em;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.container .button:not(:disabled):hover {
  background-color: #eb8181 !important;
}

.container .button:active {
  box-shadow: none;
}

.container .button:disabled,
.container .button[disabled] {
  background-color: #cccccc;
  color: #b4b4b4;
  cursor: not-allowed;
}

.pair {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pair button {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}

.gcLink {
  font-size: 0.8em;
}

.gcQR {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.btn {
  border: none;
  border-radius: 10px;
  margin-top: 1rem;
  color: var(--main-colour-2);
  background-color: var(--main-colour);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 15px;
  font-size: 1em;
  text-transform: uppercase;
  cursor: pointer;
}

.code {
  font-size: 42px;
}

.loading {
  font-size: 22px;
  height: 200px;
  width: 200px;
  padding: 2rem 0;
  text-align: center;
}

.generate {
  font-size: 20px;
  height: 200px;
  width: 200px;
  padding: 2rem 0;
  text-align: center;
}

.link {
  margin: 1rem 0;
  font-size: 14px;
  text-align: center;
}

.toastBtnGroup {
  display: flex;
  justify-content: space-between;
}

.toastBtnGroup button {
  border: none;
  border-radius: 10px;
  margin-top: 1rem;
  color: var(--main-colour-2);
  background-color: var(--main-colour);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 15px;
  font-size: 1em;
  text-transform: uppercase;
  cursor: pointer;
}

.data-filter-display {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.data-filter-display p {
  color: #6b6969;
}

.data-filter-display .data-filter-number {
  width: 22%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.data-filter-state {
  position: relative;
  user-select: none;
  cursor: pointer;
  padding-right: 3%;
}

.data-filter-state p {
  padding: 0.5rem 1rem;
  background: #fff;
  margin-right: 5px;
  border: 1px solid rgba(107, 105, 105, 0.2);
}

.data-filter-state-options {
  position: absolute;
  right: 18%;
  top: 115%;
  z-index: 10000;
  background: #ffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.data-filter-state-options ul {
  padding: 0;
  list-style: none;
}

.data-filter-state-options ul li {
  width: 200px;
  padding: 0.5rem 1rem;
}

.data-filter-state-options ul li:hover {
  background: rgb(180, 179, 179);
  color: #fff;
}

.info-section {
  margin-top: 2rem;
}

.participant-info-container {
  margin: 1rem 0;
}

.pagination {
  width: 100% !important;
  margin-bottom: 2%;
}

.pagination ul {
  gap: 5px;
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .main {
    padding: 5px;
  }
}
