.button {
  width: 265px;
  max-width: 400px;
  height: 40px;
  background-color: var(--secondary-colour-2);
  border: 2px solid var(--main-colour);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 4px;
  border-radius: 20px;
  color: var(--main-colour);
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.button:hover {
  background-color: var(--main-colour);
  color: var(--main-colour-2);
}

.disabled {
  pointer-events: none;
  background-color: grey;
}

.disabled:hover {
  background-color: grey;
}
