.content-container p {
  font-size: 0.9rem;
}

.progress-bar-container {
  background-color: var(--progress-bar-bg-color);
  height: 20px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  position: relative;
}

.progress-bar {
  height: 100%;
  border-top-left-radius: 9px;
  background-color: var(--secondary-colour);
  transition: width 1s ease;
}

.progress-bar-container span {
  color: var(--secondary-colour-2);
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8rem;
}

.red {
  color: var(--main-colour);
}
