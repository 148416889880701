
.button {
  background-color: var(--main-colour);
  color: var(--main-colour-2);
}


.button-disabled {
  background-color: var(--main-colour-2);
  color: #c4c4c4;
  border-color: #c4c4c4;
}

.button-disabled:hover {
  background-color: var(--main-colour-2);
  color: #c4c4c4;
  border-color: #c4c4c4;
}
