.role-card {
  background-color: var(--main-colour);
}

.border {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--main-colour);
  height: 80%;
  border-radius: 20px;
  padding: 15px 5px;
  transition: all 0.5s ease-in;
  margin: auto;
}

.sideBorder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--main-colour);
  border-radius: 20px;
  padding: 10px;
  transition: all 0.5s ease-in;
  height: 60%;
  margin: auto 0 auto 0;
}

.border,
.sideBorder {
  box-shadow: 0px 4px 4px rgba(90, 90, 90, 0.4);
  width: 100%;
}

.content {
  display: flex;
  padding: 0px 5px;
  transition: all 0.2s ease-in-out;
  /* min-height: 100px; */
  min-height: 12vh;
}

.name {
  color: var(--secondary-colour-2);
  text-align: center;
  font-size: calc(0.4rem + 0.3vw);
  letter-spacing: 1px;
}

@media only screen and (max-width: 600px) {
  .name {
    color: var(--secondary-colour-2);
    text-align: center;
    font-size: calc(0.6rem + 0.4vw);
  }
}
