.smiley-container {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}
.smiley-container p {
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.smiley-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.smiley-input-container p {
  font-size: 14px;
}

.smiley-input {
  padding: 0.5rem 1rem;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 1rem;
  background-color: var(--main-colour-2);
  border: 2px solid var(--main-colour);
}

.rating-number {
  font-size: 1.2rem !important;
  color: var(--main-colour);
}

.smiley-input--error {
  border: 2.5px solid #e71010;
}

.circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid var(--main-colour);
  cursor: pointer;
  user-select: none;
}

.circle-active {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid var(--main-colour);
  background-color: var(--main-colour);
}

.circle-active:first-child {
  background: url("../../../assets/RedSadFaceWhiteStore.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
}

.circle-active:last-child {
  background: url("../../../assets/RedHappyFaceWhiteStore.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
}

.smiley-input .circle-active--facil:last-child {
  background: url("../../../assets/FacilHappyFaceFilled.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
}

.smiley-input .circle--facil:last-child {
  background: url("../../../assets/FacilHappyFace.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
}

.smiley-input .circle-active--facil:first-child {
  background: url("../../../assets/FacilSadFaceFilled.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
}

.smiley-input .circle--facil:first-child {
  background: url("../../../assets/FacilSadFace.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
}

.circle:first-child {
  background: url("../../../assets/RedSadFace.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
}

.circle:last-child {
  background: url("../../../assets/RedHappyFace.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
}

.smiley-error-message {
  font-size: calc(0.5rem + 0.5vh);
  margin-top: 1.5rem;
  color: var(--main-colour);
  text-align: center;
  align-self: center;
  display: inline-block;
}

.smiley-error-message > svg {
  vertical-align: center;
}
