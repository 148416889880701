.range-wrap {
  width: 100%;

  position: relative;
}
/* SliderCustom.module.css */
input[type="range"].slider::-moz-range-progress {
  background-color: transparent;
}
input[type="range"].slider {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #eb8181;
  outline: none;

  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

input[type="range"].slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  bottom: -6px;
  z-index: 1000;
  box-shadow: none !important;
}

input[type="range"].slider::-moz-range-thumb {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  bottom: -10px;
  z-index: 1000;
  box-shadow: none !important;
}

.bubble {
  color: white;
  padding: 4px 12px;
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}
.bubble::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 25px;
  background: #000;
  top: -10px;
  left: 45%;
}

.checkIn-bubble {
  background-color: lightgray;
}
