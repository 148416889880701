.main-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: min-content;
  white-space: nowrap;
  height: fit-content;
}

.menu-container {
  position: absolute;
  top: 45px;
  background-color: var(--main-colour-80);
  border-radius: 10px;
  padding: 5px;
  z-index: 9999;
  width: fit-content;
}

.option-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.main-container input[type="checkbox"] {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 5px;
  border-radius: 5px;
}

.main-container input[type="checkbox"]:focus {
  box-shadow: 0 0 0 3px var(--primary-500);
}

.main-container label {
  font-size: 1rem !important;
}
