@import "../../../App.css";

.link {
  text-decoration: none;
}

.page-wrapper {
  background-color: var(--text-colour);
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container {
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100%;
  width: 100%;
  max-height: 700px;
  max-width: 500px;
  text-align: center;
}

.container h1,
.container h2 {
  /* Overwrite from Sidebar */
  padding: 0%;

  color: var(--main-colour);
  text-align: center;
}

.container h2 {
  font-size: 1rem;
  font-weight: normal;
}

.container h1 {
  font-size: 3rem;
  line-height: 1;
}

.container em {
  font-size: 3.5rem;
}
