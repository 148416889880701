.back-and-next {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.back-and-next.back-fixed {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: var(--secondary-colour-2-60);
  padding: 1rem 0;
  position: fixed;
  bottom: 0;
  z-index: 100;
}

.back,
.next {
  background-color: var(--secondary-colour-2-60);
  border: none;
  padding: 10px 30px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 1);
}

.back-and-next strong {
  color: var(--main-colour);
  font-size: 1rem;
}

.back-and-next button {
  cursor: pointer;
}

.next strong {
  color: var(--main-colour);
}

/* styling for next button if not selected */
.button-greyed strong {
  color: var(--main-colour);
  opacity: 30%;
}

.button-allowed strong {
  color: white;
}

.button-allowed {
  background-color: var(--main-colour);
}

.animation {
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: (scale(0.9));
  }
  70% {
    transform: (scale(1));
    box-shadow: 0 0 0 10px var(--main-colour-0);
  }
  100% {
    transform: (scale(0.9));
    box-shadow: 0 0 0 0 var(--main-colour-0);
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: (scale(0.9));
  }
  70% {
    transform: (scale(1));
    box-shadow: 0 0 0 10px var(--main-colour-0);
  }
  100% {
    transform: (scale(0.9));
    box-shadow: 0 0 0 0 var(--main-colour-0);
  }
}
