 
 /* description of skill */
.role-text {
  width: 100%;
  height: auto;
  padding: 20px 25px;
  -webkit-overflow-scrolling: touch;
  background-color: var(--secondary-colour-2-60);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; 
  overflow: hidden;
}

.role-text p {
  font-size: 1rem;
}

.steps-example {
  color: var(--main-colour);
}

.clarify-header {
  background-color: var(--main-colour);
  padding: 8px 15px;
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.clarify-header strong {
  color: var(--main-colour-2);
}

.clarify-text {
  padding: 20px;
}

.question-header {
  color: var(--main-colour);
}

.list-of-questions {
  list-style: none;
}

.role-container p {
  font-size: 1rem;
}
