.tab > button {
  border-radius: 15px 15px 0px 0px;
  background-color: white;
  border-bottom: 2px solid #7BBCAB;
  color: #565454;
  padding: 10px 15px;
  margin: 2px;
  box-shadow: none !important;
  text-transform: unset !important;
  font-weight: 700;
}

.tab > div > div {
  padding: 0px;
}

.tab > button[aria-selected="true"] {
  background-color: white;
  border: 2px solid #7BBCAB;
  border-bottom: 4px solid #7BBCAB;
  color: var(--text-colour);
}

