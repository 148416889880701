.mainContainer {
  max-width: 700px;
  min-height: 100vh;
  padding: 20px;
  flex: 1;
  margin-left: 220px;
  background-color: #FFF8F1;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.header {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Individual feedback item styling */
.feedbackItem {
  padding: 15px;
  padding-bottom: 70px;
  /* Adds space for the View details button */
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  min-height: 200px;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

/* Text content for feedback details */
.itemContent {
  color: #333;
  margin: 5px 0;
}

.metaData {
  font-size: 12px;
  color: #777;
}

/* Layout for file list if any files are attached */
.fileList {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.fileItem {
  background-color: #e0e7ff;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .mainContainer {
    padding: 15px;
    max-width: 100%;
    /* Adjust for smaller screens */
  }

  .header {
    font-size: 20px;
  }

  .feedbackItem {
    padding: 10px;
  }
}

.view-details-button {
  position: absolute;
  bottom: 15px;
  right: 15px;
  border: none;
  font-weight: bold;
  text-align: center;
}

.delete-button {
  position: absolute;
  top: 2px;
  right: 2px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 30px;
  height: 30px;
  font-size: 24px;
  clip-path: circle(50%);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* High z-index to appear above other elements */
}

.modalContent {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modalContent p {
  margin-bottom: 20px;
  /* Adds space between the text and buttons */
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  background-color: red;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: center;
}

.closeButton:hover {
  color: black;
}

.modalScrollableContent {
  max-height: 70vh;
  /* Allows space for the close button */
  overflow-y: auto;
  /* Enables vertical scrolling */
  padding-right: 10px;
}

.uploadedImage {
  margin-bottom: 20px;
  max-width: 100%;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.confirm-delete-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.confirm-delete-button:hover {
  background-color: #c0392b;
}

.cancel-button {
  background-color: #dee3e7;
  color: #333;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.cancel-button:hover {
  background-color: #b2c2c3;
}

.dropdownContainer {
  position: relative;
  width: 90%;
  min-width: 200px;
  font-family: Arial, sans-serif;
}

.dropdownButton {
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.dropdown {
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid #ccc;
  color: #333;
  background-color: #f9f9f9;
  cursor: pointer;
}

.dropdown:focus {
  outline: none;
  border-color: #1b70cb;
  /* Blue border on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.dropdownOptions {
  position: absolute;
  width: 100%;
  margin-top: 5px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.dropdownOption {
  padding: 10px 12px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.dropdownOption:hover {
  background-color: #a4b2e9;
}

.confirm-delete-button:disabled,
.cancel-button:disabled {
  background-color: #cccccc;
  /* Grey out button when disabled */
  color: #888888;
  /* Text color when disabled */
  cursor: not-allowed;
  /* Change cursor to indicate disabled state */
}

/* Disable hover effect when button is disabled */
.confirm-delete-button:disabled:hover,
.cancel-button:disabled:hover {
  background-color: #cccccc;
  /* Keep the disabled color on hover */
}

.iconTextContainer {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  word-break: break-word;
  overflow-wrap: anywhere;
  margin-bottom: 8px;
}

.icon {
  margin-right: 8px;
  /* Space between the icon and the text */
  font-size: 20px;
  flex-shrink: 0;
  /* Prevent shrinking when there is a lot of text */
}

strong {
  font-weight: 600;
  margin-right: 5px;
}

.pagination {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.activeButton {
  background-color: #4caf50 !important;
}

.paginationEllipsis {
  color: #666;
  font-size: 14px;
  margin: 0 5px;
  cursor: default;
  visibility: visible;
}

.filterLabel {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 16px;
  display: block;
  color: #464545;
}

.filtersAndChart {
  display: flex;
  /* Enables horizontal layout */
  flex-wrap: nowrap;
  /* Ensures items stay on one row */
  align-items: flex-start;
  /* Aligns items at the top */
  width: 100%;
  gap: 50px;
  margin-bottom: 60px;
}

.filterContainer {
  flex: 1;
}

.chartContainer {
  flex: 2;
  /* Allow chart to take up more space */
  max-width: 100%;
  height: 400px;
  align-items: flex-end;
}

.noDataText {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
}

.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Menu */
.dropdownMenu {
  max-height: 300px;
  overflow-y: scroll;
  position: absolute;
  top: 100%;
  background-color: white;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

/* Scrollbar */
.dropdownMenu::-webkit-scrollbar {
  width: 6px;
}

.dropdownMenu::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}

/* Dropdown Items */
.dropdownItem {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

/* Checkbox Input */
.dropdownItem input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border: 2px solid #007bff;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  background-color: white;
}

/* Checked State */
.dropdownItem input[type="checkbox"]:checked {
  background-color: #007bff;
  border-color: #0056b3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.dropdownItem input[type="checkbox"]:checked::after {
  font-size: 12px;
  color: white;
  position: absolute;
}


.dropdownItem label {
  font-size: 14px;
}

.searchInput {
  border-radius: 16px !important;
  font-size: 14px !important;
}

.totalFeedback {
  grid-column: span 3;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  color: #555555;
}