.header {
  width: 100%;
}

.guest-link-placeholder {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  margin-right: 10px;
}
.guest-link {
  color: #acd0c7;
}
.guest-link:hover {
  color: #7cc6b3;
}

.user-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-colour);
}
.user-type span {
  font-size: 0.9em;
}

.existing-user {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.existing-user span {
  text-align: center;
  margin: 10px 0;
  font-size: 0.9em;
}

.login-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-colour);
  font-size: 0.9em;
}

.signup {
  font-size: 0.9em;
  color: var(--text-colour);
}

.signup-link {
  color: rgb(134, 210, 191);
}
.signup-link:hover {
  color: rgb(60, 210, 191);
}

/* add btn link style  */
.btn-link {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
