.community-container {
  max-width: 650px;
  margin-top: 10vh;
  max-height: 75vh;
  overflow-y: auto;
  width: 100%;
  background: #fff;
  height: 100%;
  padding: 1rem;
  border-radius: 10px;
}

.community-container .nav-title {
  position: relative;
}

.community-container .nav-title button {
  position: absolute;
  right: 0;
  top: 0;
  outline: none;
  border: none;
  padding: 1rem;
  background: transparent;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.community-container .nav-title .title {
  color: #504f4f;
  text-align: center;
  padding: 1rem 2rem;
  font-weight: 500;
}

.community-container .gc-cards {
  display: flex;
  margin: 10px;
  border-bottom: 1px solid rgba(80, 79, 79, 0.2);
  padding: 1rem;
}

.community-container .gc-cards .gc-info {
  padding: 0 2rem;
  text-align: left;
}
