.redirect-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.25s;
}
.redirect-container h1 {
  text-align: center;
  font-weight: bold;
  font-size: medium;
  color: var(--text-colour);
}