.header {
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  min-height: 8vh;
  background-color: var(--secondary-colour-2);
}

.icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logo {
  font-size: 1.6em;
}
