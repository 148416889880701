.avatar-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 100px;
}

.avatar-container-rating {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
  font-size: 0.7rem;
  white-space: nowrap;
}

.triggered {
  position: relative;
  border-radius: 50%;
  border: 4px solid red;
}

.triggered svg {
  position: absolute;
}
