@import "../../App.css";

/* Removes default border for inputs */
.inputField > input {
  border: none;

  /* Styling based on figma*/
  border-radius: 30px;
  padding: 10px;
  /* font-size: 1.1em; */
  font-size: 1.75vh;
  margin: 10px;
  width: 100%;
  text-align: center;
  color: var(--main-colour);
}

.inputField > input:focus {
  outline: none;
}

/* Style for inputFields (label, text) */
.inputField {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 500px;
  margin: 0.5em 0;
}

/* Style for input in inputField */
.inputField > input {
  background-color: var(--main-colour-2);
  box-shadow: 0 0 0 3px rgba(253, 245, 230, 0.4),
    0 0 0 6px rgba(253, 245, 230, 0.5);
}

/* Placeholder disappears on focus */
.inputField > input:focus::placeholder {
  color: transparent;
}

.inputField > label {
  color: var(--main-colour-2);
  text-align: center;
}

.alert-placeholder {
  width: 25px;
  position: relative;
  text-align: center;
  bottom: 30px;
  left: 90px;
}
