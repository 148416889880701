.five-step-header-container-dummy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: var(--secondary-colour-2);
  /* margin-top: 8vh; */
  /* position: sticky; */
  position: fixed;
  top: 8vh;
  align-self: flex-start;
  z-index: 1000;
}

.five-step-header-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  height: 35px;
  width: 90%;
  max-width: 500px;
  background-color: var(--main-colour);
  border-radius: 40px;
}

.five-step-header-container strong {
  color: var(--secondary-colour-2);
  font-size: 1.2rem;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.lightning-container {
  position: absolute;
  left: 1%;
  top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clearButtonStyle {
  /* Reset button style */
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.guide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1%;
  top: 10%;
}

/* Fade in animation */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
