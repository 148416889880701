.users-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--main-colour-2);
  padding: 20px;
  width: 90%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.avatar-container {
  margin: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.username {
  font-size: 1rem;
  color: var(--main-colour);
}
