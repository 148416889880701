.container {
  max-width: 96vw;
  text-align: center;
  width: 100%;  
}

.description {
  color: var(--text-colour);
  font-weight: 700;
}
