.title {
  font-size: calc(1em + 1vmin);
  width: 100%;
}

.instructions {
  font-size: .8rem
}

.title, .instructions {
  color: var(--secondary-colour-2);
}

.share-container {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
