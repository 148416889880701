.content {
  width: 100%;
  height: 100%;
  background: #fdf5e6;
}

.resizable-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down !important; /* You can also use 'cover' or 'scale-down' based on your preference */
}

.content .arrow {
  position: absolute;
  left: 5%;
  top: -1rem;
  animation: bounce 2s ease infinite;
}

.content .trigger-text {
  position: absolute;
  left: 5%;
  margin-top: 2rem;
  width: 50%;
  border: 2px solid #eb8181;
  border-radius: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
  background: #fdf5e6;
  padding: 5px 15px;
  color: #eb8181;
}

.content .trigger-instruction {
  position: absolute;
  left: 0;
  margin: 30% 5%;
  background: #fdf5e6;
  padding: 2rem 1rem;
  border: 2px solid #eb8181;
  border-radius: 20px;
  padding-bottom: 0;
}

.content .trigger-instruction h6 {
  color: #565454;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  /* or 125% */
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.content .trigger-instruction p {
  color: #565454;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.3em;
  margin-bottom: 2rem;
  /* or 125% */
  text-align: center;
}

.content .trigger-instruction .trigger-instruction-red {
  color: #eb8181;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.3em;

  /* or 125% */
  text-align: center;
}

.content .leref {
  position: absolute;
  right: 0;
  width: auto;
  top: 14rem;
}

.container .button-container {
  background-color: inherit;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 2rem;
}

.container .button-container button {
  background-color: var(--secondary-colour-2-60);
  border: none;
  padding: 10px 30px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 1);
  color: #eb8181;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

@keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}
