.header {
  width: 100%;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputs span {
  font-size: 0.8em;
  align-self: flex-start;
  color: var(--text-colour);
}

.form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputs .email {
  margin: 0 auto;
  font-weight: 600;
  font-size: 1em;
}

.button {
  margin: 3em;
}
