.back-and-next {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.back-and-next.back-fixed {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: #eb8181;
  padding: 1rem 0;
  position: fixed;
  bottom: 0;
  z-index: 100;
}

.back,
.next {
  background-color: #eb8181;
  border: none;
  padding: 10px 30px;
  border-radius: 20px;
}

.back.button-greyed,
.next.button-greyed {
  background-color: grey;
  color: #ffffff;
  opacity: 30%;
}

.back-and-next strong {
  color: #ffffff;
  font-size: 1rem;
}

.back-and-next button {
  cursor: pointer;
}

.next strong {
  color: #ffffff;
}

/* styling for next button if not selected */

.button-allowed strong {
  color: white;
}

.button-allowed {
  background-color: #eb8181;
}

.animation {
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: (scale(0.9));
  }
  70% {
    transform: (scale(1));
    box-shadow: 0 0 0 10px var(--main-colour-0);
  }
  100% {
    transform: (scale(0.9));
    box-shadow: 0 0 0 0 var(--main-colour-0);
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: (scale(0.9));
  }
  70% {
    transform: (scale(1));
    box-shadow: 0 0 0 10px var(--main-colour-0);
  }
  100% {
    transform: (scale(0.9));
    box-shadow: 0 0 0 0 var(--main-colour-0);
  }
}
