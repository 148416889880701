.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.6);;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: white;
    text-align: center;
    border-radius: 10px;
}

.popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: 'pointer';
    color: #EB8181;
}

.popup-inner h2 {
    font-size: 24px;
    font-weight: bold;
    color: #565454;
  }

  .popup-inner p {
    color: #565454;
  }
