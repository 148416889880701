.box {
  background: linear-gradient(
    to bottom right,
    var(--main-colour),
    var(--main-colour-80),
    var(--main-colour),
    var(--main-colour-80),
    var(--main-colour)
  );
  border-radius: 25px;
  padding-top: 3.5vh;
  margin-top: 10px;
  padding-bottom: 5vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  color: var(--main-colour-2);
  margin: 0 auto;
  width: 80%;
  max-width: 500px;
  font-weight: 600;
  white-space: pre-wrap;
}

.header-description {
  color: var(--main-colour-2);
  font-size: 12px;
  margin: 0 auto;
  width: 80%;
  max-width: 500px;
  white-space: pre-wrap;
  padding-bottom: 2vh;
}

.errorMessage {
  font-size: calc(0.5rem + 0.5vh);
  margin-top: 1.5rem;
  color: var(--main-colour-2);
  text-align: center;
  align-self: center;
  display: inline-block;
}

.errorMessage > svg {
  vertical-align: middle;
}
